import React from 'react';
import './LandingPage.css';
import logo from '../img/RockYourWork.png';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="logo-container">
        <img src={logo} alt="Logo Rock Your Work" />
      </div>
      <h1 className="coming-soon-text">Your performance tools are coming soon...</h1>
    </div>
  );
};

export default LandingPage;
